import logo from './logo.svg';
import './App.css';
import { Route, Routes, useLocation, Navigate  } from 'react-router-dom'
import Booking from './Pages/Booking/Booking'
import Detail from './Pages/Detail/Detail'
import Success from './Pages/Success/Success'

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path='/*' element={<Booking />}>
        </Route>
        <Route path='/otel/*' element={<Detail />}>
        </Route>
        <Route path='/success' element={<Success />}>
        </Route>
      </Routes>
    
    </div>
  );
}

export default App;
