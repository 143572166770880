import { useState } from 'react';
import { format } from 'date-fns';
import { az } from "react-day-picker/locale";

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

export default function Calender({selected, setSelected}) {

  if (selected) {
    console.log(selected)
  }
  return (
    <DayPicker
      mode="range"
      selected={selected}
      onSelect={setSelected}
      locale={az}
    />
  );
}