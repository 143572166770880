import React from "react";
import './Success.css'
import { ReactComponent as SuccessIcon } from '../../assets/success.svg';
const Success = () => {
    return (
        <div className="success-container">
            <SuccessIcon/>
            <h2>Rezervasyonunuz alındı. Otel sahibi sizinlə əlaqə saxlayacaq.</h2>

        </div>
    )

}

export default Success;