import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import './Detail.css'
import Gallery from '../../Components/Gallery/Gallery'
import { ReactComponent as Calender } from '../../assets/calender.svg';
import { ReactComponent as Person } from '../../assets/person.svg';
import { ReactComponent as PersonBlue } from '../../assets/person_blue.svg';
import { ReactComponent as Area } from '../../assets/area.svg';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const Detail = () => {
    const location = useLocation();
    let navigate = useNavigate();
    const [room, setRoom] = useState([]);
    let Difference_In_Time =
        location.state.date.to.getTime() - location.state.date.from.getTime();
    let Difference_In_Days =
        Math.round
            (Difference_In_Time / (1000 * 3600 * 24));

    function sendReservation(roomId, price) {
        axios.post('https://api.gezz.az/otel/saveReservation', {
            otel_id: location.state.id,
            id: location.state.user,
            from: location.state.date.from,
            to: location.state.date.to,
            price: price * Difference_In_Days,
            adult: location.state.adult,
            child: location.state.child,
            room_id: roomId
        }).then(function (response) {
            console.log(response);
            let path = `/success`;
            navigate(path);
        })
            .catch(function (error) {
                console.log(error);
            });
    }
    function change(e) {
        let path = `/id=${location.state.id}`;
        navigate(path);
    }

    useEffect(() => {
        axios.post('https://api.gezz.az/otel/searchRoom', {
            otel_id: location.state.id,
            adult: parseInt(location.state.adult) + parseInt(location.state.child)
        }).then(function (response) {
            console.log(response);
            setRoom(response.data.rooms)
        })
            .catch(function (error) {
                console.log(error);
            });

    }, []);

    return (
        <div>
            <div className="filters">

                <h4 style={{ margin: 5 }}>{location.state.name}</h4>
                <div className="date">
                    <Person />
                    <span>
                        {location.state.adult}  Böyüklər
                        {location.state.child > 0 &&
                            <span>
                                &nbsp; + {location.state.adult}  Uşaqlar
                            </span>
                        }
                    </span>
                </div>
                <div className="date">
                    <Calender />
                    <span>{location.state.date.from.toLocaleDateString()} - {location.state.date.to.toLocaleDateString()}</span>
                </div>
                <button className="change-btn" onClick={change}>axtarışın parametlərini dəyişin</button>
            </div>
            <h3>Sizə uyğun olan otağı seçin</h3>
            <hr>
            </hr>
            {room.length > 0 && room.map(_r => {
                return (
                    <div className="otel-detail">
                        <Gallery urls={_r.images} />
                        <h5 className="name">{_r.name}</h5>
                        <div className="detail-container">
                            <div className="area border-right">
                                <Area />
                                <span>{_r.area}m²</span>
                            </div>
                            <div className="area">
                                <PersonBlue />
                                <span>Maksimum qonaq: {_r.capacity} nefer</span>
                            </div>
                        </div>
                        <div className="description">
                            {_r.description}
                        </div>
                        <div className="price">
                          {Difference_In_Days} gecə | ₼ {_r.price * Difference_In_Days}
                        </div>
                        <button className="rezervation-btn" onClick={() => sendReservation(_r.id, _r.price)}>Rezervasiya etmək</button>
                    </div>
                )

            })}
            {
                room.length === 0 &&
                (
                    <div>Uyğun otaq tapılmadı</div>
                )
            }
        </div>
    )

}

export default Detail;