import React, { useState } from "react";
import "./Counter.css";
const Counter = ({ name, count, setCount, min }) => {
    function increment(e) {
        setCount(prevCount => prevCount += 1);
        // setState(function (prevCount) {
        //   return (prevCount += 1);
        // });

    }

    function decrement() {
        setCount(function (prevCount) {
            if (prevCount > 0 && prevCount > min) {
                return (prevCount -= 1);
            } else {
                if (min && prevCount <= min)
                    return (prevCount = 1)
                else
                    return (prevCount = 0);
            }
        });
    }
    return (
        <div className="row">
            <span className="title">{name}</span>
            <div className="counter">
                <button className="counter-btn" onClick={decrement}>-</button>
                <span className="count">{count}</span>
                <button className="counter-btn" onClick={increment}>+</button>

            </div>
        </div>
    );
};

export default Counter;
