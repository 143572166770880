import ImageGallery from "react-image-gallery";
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import "./Gallery.css"

const Gallery = ({urls}) => {

  const images = [];
  urls.forEach(_img => {
    images.push(
      {
        original: _img,
      }
    )
  });
  
    return <ImageGallery showPlayButton={false}
    showBullets={true} showThumbnails={false} items={images} />;
  
}
export default Gallery