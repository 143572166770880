import React, { useState, useEffect } from "react";
import Calender from '../../Components/Calender'
import Counter from '../../Components/Counter/Counter'
import './Booking.css'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ReactComponent as GezzIcon } from '../../assets/gezz.svg';

const Booking = () => {
    const [date, setDate] = useState();
    const [adult, setAdult] = useState(1);
    const [child, setChild] = useState(0);
    const [alert, setAlert] = useState();
    const [main, setMain] = useState(false);

    const [name, setName] = useState();
    let navigate = useNavigate();
    function submit(e) {
        if (!date) {
            setAlert('tarixi seçin')
            return
        }
        let path = `otel/${name}`;
        
        navigate(path, { state: { name: name, date: date, adult: adult, child: child, user: window.location.pathname.split('=')[2], id: window.location.pathname.split('=')[1].split('&')[0] } });
    }

    useEffect(() => {
        if(window.location.pathname.includes('=') && window.location.pathname.split('=').length === 3 )
        {
            axios.post('https://api.gezz.az/otel/getOtelById', {
                id: window.location.pathname.split('=')[1].split('&')[0] 
            }).then(function (response) {
                console.log(response);
                setName(response.data.otel.name)
            })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else{
            setMain(true)        
        }
  
    }, []);
    if(main){
        return(
            <div className="notfound">
                <GezzIcon/>
                <h3>
            Axtardığınız oteli tapa bilmədik. zəhmət olmasa daha sonra yenidən cəhd edin
            </h3>
            <hr></hr>
            <h2>Gezz Səyahət platformasıdır
            </h2>
            <div className="about">
                Siz burada müxtəlif rayonlara gedən istifadəçiləri tapıb, birlikdə səyahət edə və yol xərcini bölüşə bilərsiniz.
                Müxtəlif şəhərlərdəki Restoran/Otel/Əyləncləri Gezz vasitəsilə kəşf edə bilərsiniz.
                Səyahət üçün maşın lazımdırsa, Gezz tətbiqində kirayə maşınları incələyə bilərsiniz.
            </div>
          

        </div>
        )
    }

    return (
        <div className="main">
            <h2>{name}</h2>
            <Calender selected={date} setSelected={setDate} />
            {date && date?.from && date?.to &&
                <div className="date-container">
                    <div className="daterange">
                        Gəliş:
                        <span> {date.from.toLocaleDateString()}</span>
                    </div>
                    <div className="daterange">
                        Gediş:
                        <span> {date.to.toLocaleDateString()}</span>
                    </div>
                </div>
            }
            <div className="line"></div>
            {/* <Counter name={'Otaq'} count={room} setCount={setRoom} /> */}
            <Counter name={'Böyük'} count={adult} setCount={setAdult} min={1} />
            <Counter name={'Uşaq'} count={child} setCount={setChild} />
            {alert &&

                <span className="alert">{alert}</span>
            }
            <button className="button" onClick={submit}> Mövcudluğu yoxlamaq</button>
        </div>
    )

}

export default Booking;